<template>
	<div class="person-container" ref="personCont">
		<div class="jessica-experience">
			<div class="jessica-picture-container">
				<img
					class="jessica-img"
					v-if="experience.image.image"
					:src="imageUrlFor(experience.image.image)"
				/>
			</div>
			<h1 class="jessica-name" ref="title">{{ experience.name }}</h1>

			<p class="experience-1" ref="expOne" v-html="nl2br(experience.paragraphOne)" />
			<div class="video">
				<iframe
					width="100%"
					height="100%"
					:src="experience.vimeoUrl"
					frameborder="0"
					allow="autoplay; encrypted-media"
					allowfullscreen=""
				></iframe>
			</div>

			<p v-if="experience.paragraphTwo" class="experience-2" ref="expTwo" v-html="nl2br(experience.paragraphTwo)" />
		</div>
	</div>
</template>

<script>
import sanity from "../../client";
import imageUrlBuilder from "@sanity/image-url";
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import { SplitText } from "gsap/dist/SplitText";
import utils from "../../mixins/utils";
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);
const imageBuilder = imageUrlBuilder(sanity);

export default {
	name: "Bio",
	props: {
		blockData: { type: Object, default: () => {} }
	},
	mixins: [utils],
	data: () => ({
		// currentStory: "4d7801ca-673a-4de7-9d82-29dad454d1ec"
	}),
	updated() {
		this.timeLine();
		ScrollTrigger.create({
			trigger: ".experience-1",
			toggleActions: "play none none none",
			onEnter: () => this.timeLineExpOne(),

			onLeaveBack: (self) => self.disable()
		});
		// ScrollTrigger.create({
		// 	trigger: ".experience-2",
		// 	toggleActions: "play none none none",
		// 	onEnter: () => this.timeLineExpTwo(),

		// 	onLeaveBack: (self) => self.disable()
		// });
	},
	computed: {
		experience() {
			return this.blockData
		},
	},
	methods: {
		/*fetchDataPersonTwo() {
			this.error = this.personTwo = null;
			this.loading = true;
			/*sanity.fetch(queryPersonTwo).then(
				(personTwoExperiences) => {
					this.loading = false;
					this.personTwoExperiences = personTwoExperiences;
				},
				(error) => {
					this.error = error;
				}
			);
		},*/
		imageUrlFor(source) {
			return imageBuilder.image(source);
		},
		pushStory(id) {
			this.currentStory = id;
		},
		timeLine() {
			let tl = gsap.timeline(),
				mySplitText = new SplitText(this.$refs.title, {
					type: "lines"
				}),
				lines = mySplitText.lines;
			gsap.set(this.$refs.lilaTitle, { perspective: 400 });
			tl.from(
				lines,
				{
					y: 0,
					opacity: 0,
					duration: 0.5,
					transformOrigin: "20% 0 0"
				},
				"+=0"
			);
		},
		timeLineExpOne() {
			this.$refs.expOne.style.opacity = 1;
		},
		timeLineExpTwo() {
			this.$refs.expTwo.style.opacity = 1;
		}
	}
	/*created() {
		this.fetchDataPersonTwo();
		setTimeout(function () {
			ScrollTrigger.refresh();
		}, 50);
	}*/
};
</script>

<style scoped>
.work-together-container {
	position: relative;
	background: none;
	top: 12.5vw;
}

h1 {
	text-transform: uppercase;
}

.video {
	margin-bottom: 80px;
}

@media (max-aspect-ratio: 200/200) {
	.work-together-container {
		margin-top: 20vw;
	}
}
.person-container {
	width: 100vw;
}
</style>
