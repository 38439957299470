<template>
  <a v-if="externalLink" :class="classList" :href="externalLink" target="_blank">
    <span v-if="button" ref="buttonBg" />
    <slot></slot>
  </a>
  <router-link v-else :class="[classList, textSwitch ? 'text-white delay-500 duration-300 ease-in-out' : '']" :to="formattedTarget">
    <span v-if="button" ref="buttonBg" />
    <slot></slot>
  </router-link>
</template>

<script>
/*
 * Link Component usage:
 * Set underline to true to include underline animation on hover
 * Set button to true to set button styling on the link
 * Set hoverBg to a valid tailwind color (see tailwind.config.js) to enable button hover effect
 * External links are automatically new tabs, internal links are automatically nuxt-links.
 */
export default {
  name: 'Link',
  props: {
    link: { type: Object, default: () => {} },
    to: { type: String, default: '' },
    underline: { type: Boolean, default: false },
    button: { type: Boolean, default: false },
    hoverBg: { type: String, default: 'orange' },
    textSwitch: { type: Boolean, default: false }
  },
  computed: {
    classList() {
      return {
        'focus:outline-none': true,
        'underline-link': this.underline,
        'button-link': this.button
      }
    },
    formattedTarget() {
      if (this.link.pages) {
        return '/' + this.link.pages.slug?.current
      }
      return this.link?.externalLink ?? '#'
    },
    externalLink() {
      let link = false
      if (this.link?.externalLink) {
        link = this.link.externalLink
      }

      if (link && (link.includes('://') || link.includes('mailto:') || link.includes('tel:'))) {
        return link
      }
      return false
    }
  }
}
</script>

<style scoped>
.button-link {
  font-family: Arial, Helvetica, sans-serif;
}
.underline-link::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: currentColor;
  top: 100%;
  left: 0;
  pointer-events: none;
  transform-origin: 100% 50%;
  transform: scale3d(0, 1, 1);
  transition: transform 0.3s;
}

.underline-link:hover::before {
  transform-origin: 0% 50%;
  transform: scale3d(1, 1, 1);
}
</style>
