<template>
	<div class="retreat-container flex flex-col" :class="reverse">
		<div class="retreat-text-container">
			<h1 class="retreat-title" ref="retreatTitle">
				{{ blockData.Title }}
			</h1>
			<!-- <p
				class="retreat-paragraph"
				v-bind:class="{
					retreatParagraphActive: isRetreatParagraphActive
				}"
			>
				<span v-html="nl2br(blockData.paragraph)" />
			</p> -->

			<div
				v-for="(points, index) in blockData.bullets"
				:key="index"
				style="margin-top: 50px"
			>
				<p class="full-program">{{ points.title }}</p>
				<div
					class=""
					v-for="point in points.bullet"
					:key="point._key"
					ref="checkOne"
				>
					<div class="checkbox-img-container checkActive"></div>
					<p class="checkbox-paragraph 1">
						{{ point }}
					</p>
				</div>
			</div>
		</div>
		<div class="retreat-picture-container retreatActive">
			<img :src="imageUrlFor(blockData.image)" class="retreat-picture" />
		</div>
	</div>
</template>
<script>
import sanity from "../../client";
import imageUrlBuilder from "@sanity/image-url";
const imageBuilder = imageUrlBuilder(sanity);

import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import { SplitText } from "gsap/dist/SplitText";
import utils from "../../mixins/utils";

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);
export default {
	name: "ProgramContent",
	props: {
		blockData: { type: Object, default: () => {} }
	},
	mixins: [utils],
	data: () => ({
		isRetreatActive: false,
		isRetreatParagraphActive: false,
		isCheckOneActive: false,
		isCheckTwoActive: false,
		isCheckThreeActive: false,
		isCheckFourActive: false
	}),
	computed: {
		reverse() {
			if (this.blockData.reverseRow === true) {
				return "row-reverse";
			}
			return "";
		}
	},
	updated() {
		ScrollTrigger.create({
			trigger: ".retreat-title",
			toggleActions: "play none none none",
			onEnter: () => (this.isRetreatActive = true),

			start: () => "top " + window.innerHeight * 1,

			onLeaveBack: (self) => self.disable()
		});

		ScrollTrigger.create({
			trigger: ".retreat-paragraph",
			toggleActions: "play none none none",
			onEnter: () => (this.isRetreatParagraphActive = true),

			start: () => "top " + window.innerHeight * 0.85,

			onLeaveBack: (self) => self.disable()
		});

		ScrollTrigger.create({
			trigger: ".retreat-title",
			toggleActions: "play none none none",
			onEnter: () => this.timelineRetreat(),

			start: () => "top " + window.innerHeight * 0.9,

			onLeaveBack: (self) => self.disable()
		});

		ScrollTrigger.create({
			trigger: this.$refs.checkOne,
			toggleActions: "play none none none",
			onEnter: () => (this.isCheckOneActive = true),

			start: () => "top " + window.innerHeight * 0.9,

			onLeaveBack: (self) => self.disable()
		});
		ScrollTrigger.create({
			trigger: this.$refs.checkTwo,
			toggleActions: "play none none none",
			onEnter: () => (this.isCheckTwoActive = true),

			start: () => "top " + window.innerHeight * 0.9,

			onLeaveBack: (self) => self.disable()
		});
		ScrollTrigger.create({
			trigger: this.$refs.checkThree,
			toggleActions: "play none none none",
			onEnter: () => (this.isCheckThreeActive = true),

			start: () => "top " + window.innerHeight * 0.9,

			onLeaveBack: (self) => self.disable()
		});
		ScrollTrigger.create({
			trigger: this.$refs.checkFour,
			toggleActions: "play none none none",
			onEnter: () => (this.isCheckFourActive = true),

			start: () => "top " + window.innerHeight * 0.9,

			onLeaveBack: (self) => self.disable()
		});
	},

	methods: {
		imageUrlFor(source) {
			return imageBuilder.image(source);
		},
		timelineRetreat() {
			this.$refs.retreatTitle.style.opacity = "1";
			let tl = gsap.timeline(),
				mySplitText = new SplitText(this.$refs.retreatTitle, {
					type: "lines"
				}),
				lines = mySplitText.lines;

			gsap.set(this.$refs.retreatTitle, { perspective: 400 });

			tl.from(
				lines,
				{
					y: 40,
					opacity: 0,
					duration: 0.6,
					stagger: 0.1,
					rotationX: 80,
					transformOrigin: "20% 0 0"
				},
				"+=0"
			);
		}
	}
};
</script>

<style>
.retreat-container {
	position: relative;
	left: 0;
	margin-top: 10vw;
	width: 100vw;
	height: 64vw;
	display: flex;
	justify-content: space-evenly;
}

.row-reverse {
	flex-direction: row-reverse;
}

.row-reverse .retreatActive {
	transform: rotate(-4deg);
	opacity: 1;
}

@media (min-aspect-ratio: 200/200) {
	.row-reverse .retreat-text-container {
		margin-left: -7vw;
	}
}

.retreat-text-container {
	left: 16vw;
	height: 40vw;
	width: 30vw;
	top: 0;
}

.retreat-container:nth-child(1) {
		height: 116vw;
	}

.retreat-title {
	position: relative;
	text-align: left;
	font-size: 6.25vw;
}

.retreat-paragraph {
	position: relative;
	text-align: left;
	margin-top: -3vw;
	opacity: 1;
	transition: 1s;
	font-family: DM sans;
	font-size: 1.3vw;
	width: 37vw;
}


.retreatParagraphActive {
	opacity: 1;
}
.retreat-picture-container {
	right: 0vw;
	width: 28vw;
	height: 42vw;
	transform: rotate(0deg);
	top: 2vw;
	overflow: hidden;
	opacity: 0.2;
	transition: 1s;
}

.what-you-get {
	position: relative;
	text-align: left;
	opacity: 1;
	transition: 1s;
	font-family: DM sans;
	font-size: 1.3vw;
	width: 37vw;
}

.retreatActive {
	transform: rotate(4deg);
	right: 10vw;
	opacity: 1;
}

.retreat-picture {
	position: relative;
	width: 100%;
}

.checkbox {
	position: relative;
	margin-top: 0.5vw;
	left: 16.5vw;

	width: 35vw;
	opacity: 0;
	top: 42vw;
	transition: 1s;
}
#checkthree {
	margin-top: 2.5vw;
}

.checkboxActive {
	opacity: 1;
}

.checkbox-img-container {
	position: relative;
	transition: 1s;
	border-radius: 50vw;
	background: #d4c09e;
	width: 0.5vw;
	height: 0.5vw;
	top: 1vw;
}

.checkActive {
	transform: scale(3);
	display: block;
}

.checkbox-img {
	width: 100%;
}

.checkbox-paragraph {
	position: relative;
	top: 0;
	margin-top: 0vw;
	margin-left: 6vw;
	font-size: 1.3vw;
	font-family: DM sans;
	text-align: left;
}

.full-program {
	position: relative;
	text-align: left;
	opacity: 1;
	transition: 1s;
	font-family: DM sans;
	font-size: 1.3vw;
}

.cost-text {
	position: relative;
	text-align: left;
	top: 42vw;
	margin-left: 16vw;
	margin-top: 3vw;
	opacity: 1;
	transition: 1s;
	font-family: DM sans;
	font-size: 1.3vw;
}

#checkfive {
	margin-top: 2.5vw;
}
@media (max-aspect-ratio: 200/200) {
	.retreat-container {
		padding-top: 0;
		height: 180vw;
		overflow-y: visible;
		width: auto;
		flex-direction: column-reverse;
		display: flex;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.retreat-container:nth-of-type(2) {
		height: 52rem;
	}

	.retreat-picture-container {
		max-height: 200px;
		width: 60vw;
		height: 100vw;
	}

	.retreat-container:nth-of-type(3) .retreat-picture-container {
		width: 88vw;
	}

	.retreat-blur {
		position: absolute;
		width: 60vw;
		height: 60vw;
		left: -15vw;
		bottom: -40vw;

		background: rgba(255, 255, 255, 0.8);
		filter: blur(8vw);
		transform: rotate(4deg);
		z-index: +5;
	}

	.retreat-text-container {
		left: 5vw;
		width: 90vw;
		height: 640px;
	}
	.retreat-title {
		font-size: 11.25vw;
		width: 30vw;
	}
	.retreat-paragraph {
		font-size: 3.8vw;
		width: 90vw;
	}
	.checkbox {
		top: 115vw;
		left: 6vw;
	}

	.checkbox-paragraph {
		font-size: 3.8vw;
		left: 10vw;
		width: 60vw;
	}

	.retreatPicActive {
		right: 6vw;
	}

	.checkbox-img-container {
		width: 1.5vw;
		height: 1.5vw;
	}

	.what-you-get {
		position: relative;

		margin-top: 10vw;

		font-family: DM sans;
		font-size: 3.8vw;
		width: 37vw;
	}

	.full-program {
		font-size: 3.8vw;
		width: 80vw;
	}

	#checkthree {
		margin-top: -64vw;
	}

	.cost-text {
		position: relative;
		text-align: left;
		top: 42vw;
		margin-left: 5vw;
		margin-top: 78vw;
		opacity: 1;
		transition: 1s;
		font-family: DM sans;
		font-size: 3.8vw;
	}

	#checkfive {
		margin-top: -64vw;
	}
}
</style>
