<template>
	<div class="letter-container">
		<div class="letter" ref="letter">
			<p class="intro">{{ blockData.title }}</p>
			<sanity-rich-text
				v-if="blockData.text"
				:blocks="blockData.text"
			></sanity-rich-text>
		</div>
	</div>
</template>

<script>
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

export default {
	name: "TextBlock",
	props: {
		blockData: {
			type: Object,
			default: () => {}
		}
	},
	created() {
		setTimeout(function () {
			ScrollTrigger.refresh();
		}, 50);
	},
	mounted() {
		ScrollTrigger.create({
			trigger: ".letter",
			toggleActions: "play none none none",
			onEnter: () => this.letterContOpacity(),

			start: () => "top " + window.innerHeight * 0.85,

			onLeaveBack: (self) => self.disable()
		});
	},
	methods: {
		letterContOpacity() {
			this.$refs.letter.style.opacity = 1;
		}
	}
};
</script>

<style>
@font-face {
	font-family: "BonVivant";
	src: url("/fonts/BonVivantSerif/font.woff2") format("woff");
	font-weight: bold;
}

@font-face {
	font-family: "BonVivant";
	src: url("/fonts/BonVivantSerif/font.woff") format("woff");
	font-weight: normal;
}

.letter-container {
	background: #f7f5f2;
	text-align: left;
	padding-top: 1vw;
	padding-bottom: 5vw;
	opacity: 1;
}
.letter {
	font-family: DM sans;
	width: 80vw;
	margin: auto;
	margin-left: 7vw;
	opacity: 0;
	font-size: 1.4vw;
	transition: 0.5s;
}

.intro {
	font-family: BonVivant;
	text-transform: uppercase;
	font-size: 2.7vw;
	font-weight: normal;
}

@media (max-aspect-ratio: 200/200) {
	.intro {
		font-size: 8.7vw;
	}
	.letter {
		font-size: 3.8vw;
		width: 80vw;
	}

	.letter-container {
		padding-bottom: 15vw;
		margin-top: 00vw;
	}
}
</style>
